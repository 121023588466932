import {css} from '@emotion/react'
import ShootingStar from '../../../images/app/shooting-star.svg'
import IOSButton from './IOSButton'
import QrCodeWidget from './QrCodeWidget'
import GooglePlayWidget from './GooglePlayWidget'
import GooglePlayCta from './GooglePlayCta'

const translations = {
    en: {
        title: 'Start here, stay private',
        description: 'Install Startpage to ensure safer search exploration.',
    },
    de: {
        title: 'Starte hier, bleib privat',
        description: 'Installiere Startpage, um sicherer zu suchen.',
    },
    nl: {
        title: 'Begin hier, blijf privé',
        description: 'Installeer Startpage om veiliger te zoeken.',
    },
}

const Section4 = ({languageCode, experimentId, os, browser}) => {
    const text = translations[languageCode]
    return (
        <section
            className="section4"
            css={css`
                position: relative;
                text-align: center;
                background: linear-gradient(180deg, #17203b 0%, #6677fb 100%);
                padding: 4rem 1rem 6rem;
                .title,
                .description {
                    color: #fff;
                }
                .title {
                    font-size: 40px;
                    margin: auto;
                    font-weight: 800;
                    @media (max-width: 900px) {
                        font-size: 32px;
                        max-width: 220px;
                    }
                }
                .description {
                    margin: 10px auto 40px;
                    @media (max-width: 900px) {
                        margin: 10px auto 22px;
                        max-width: 250px;
                    }
                }
            `}
        >
            <div
                className="inner-container"
                css={css`
                    max-width: 800px;
                    margin: 0 auto;
                    position: relative;
                `}
            >
                <div
                    className="section-4-text"
                    css={css`
                        position: relative;
                    `}
                >
                    <div
                        className="star-container"
                        css={css`
                            position: absolute;
                            top: -30px;
                            left: 30px;
                        `}
                    >
                        <ShootingStar />
                    </div>
                    <h2 className="title">{text.title}</h2>
                    <p className="description">{text.description}</p>
                </div>
                <div
                    className="desktop-widgets-section"
                    css={css`
                        display: flex;
                        gap: 16px;
                        justify-content: center;
                        @media (max-width: 900px) {
                            display: none;
                        }
                    `}
                >
                    <QrCodeWidget languageCode={languageCode} experimentId={experimentId} />
                    <GooglePlayWidget
                        languageCode={languageCode}
                        os={os}
                        experiment={experimentId}
                        ctaType="unsupported-android-cta-2"
                    />
                </div>
                <div
                    className="mobile-cta-section"
                    css={css`
                        display: none;
                        @media (max-width: 900px) {
                            display: block;
                        }
                    `}
                >
                    {os === 'android' ? (
                        <GooglePlayCta
                            ctaType="cta-3"
                            experiment={experimentId}
                            os={os}
                            browser={browser}
                            languageCode={languageCode}
                        />
                    ) : (
                        <IOSButton
                            ctaType="cta-3"
                            experiment={experimentId}
                            os={os}
                            browser={browser}
                            languageCode={languageCode}
                        />
                    )}
                </div>
            </div>
        </section>
    )
}

export default Section4
