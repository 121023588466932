import {css} from '@emotion/react'
import {colors, gethomeLink} from '../../../constants'
import StartpageLogoRainbow from '../../../images/startpage-logo-rainbow.svg'
import linkedinIcon from '../../../images/4624/socialIcons/linkedin.svg'
import twitterIcon from '../../../images/4624/socialIcons/x.svg'
import mastodonIcon from '../../../images/4624/socialIcons/mastodon.svg'
import discordIcon from '../../../images/4624/socialIcons/discord.svg'

const socialLinksData = [
    {
        url: `https://www.linkedin.com/company/startpage.com`,
        icon: linkedinIcon,
        label: `linkedin`,
    },
    {
        url: `https://twitter.com/startpage`,
        icon: twitterIcon,
        label: `twitter`,
    },
    {
        url: `https://mastodon.social/@StartpageSearch`,
        icon: mastodonIcon,
        label: `mastodon`,
    },
    {
        url: `https://discord.com/invite/PKEBCm7bUV`,
        icon: discordIcon,
        label: `discord`,
    },
]

const footerLinks = {
    en: [
        {
            text: `Privacy policy`,
            link: `https://www.startpage.com/en/privacy-policy`,
        },
        {
            text: `Press`,
            link: `https://www.startpage.com/privacy-please/press`,
        },
        {
            text: `About us`,
            link: `https://www.startpage.com/en/about-us`,
        },
        {
            text: `Blog`,
            link: `https://www.startpage.com/privacy-please`,
        },
    ],
    de: [
        {
            text: `Datenschutzrichtlinie`,
            link: `https://www.startpage.com/de/privacy-policy`,
        },
        {
            text: `Presse`,
            link: `https://www.startpage.com/nix-zu-verbergen/pressezentrum`,
        },
        {
            text: `Über Uns`,
            link: `https://www.startpage.com/de/about-us`,
        },
        {
            text: `Blog`,
            link: `https://www.startpage.com/nix-zu-verbergen/`,
        },
    ],
    nl: [
        {
            text: `Ons privacybeleid`,
            link: `https://www.startpage.com/nl/privacy-policy`,
        },
        {
            text: `Pers`,
            link: `https://www.startpage.com/privacy-please/press`,
        },
        {
            text: `Over Startpage`,
            link: `https://www.startpage.com/nl/about-us`,
        },
        {
            text: `Blog`,
            link: `https://www.startpage.com/privacy-please`,
        },
    ],
}

const Footer = ({languageCode}) => {
    const links = footerLinks[languageCode]
    return (
        <footer
            css={css`
                background: ${colors.black};
                padding: 57px 2rem;
            `}
        >
            <div
                css={css`
                    max-width: 966px;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;
                `}
            >
                <a href={gethomeLink(languageCode)} target="__blank">
                    <StartpageLogoRainbow />
                </a>
                <div
                    className="social-icons"
                    css={css`
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 24px;
                        margin: 40px auto;
                        svg {
                            width: 40px;
                            height: 40px;
                        }
                    `}
                >
                    {socialLinksData.map((socialItem, i) => {
                        const {url, icon, label} = socialItem
                        return (
                            <a
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label={label}
                                key={i}
                                css={css`
                                    svg path {
                                        fill: ${colors.black};
                                    }
                                    &:hover {
                                        svg circle {
                                            fill: ${colors.whisperLight};
                                        }
                                    }
                                `}
                            >
                                {icon()}
                            </a>
                        )
                    })}
                </div>
                <div
                    className="links"
                    css={css`
                        display: flex;
                        align-items: center;
                        gap: 40px;
                        @media (max-width: 900px) {
                            flex-direction: column;
                            gap: 0;
                        }
                    `}
                >
                    {links.map((link, i) => {
                        const {text, link: url} = link
                        return (
                            <a
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label={text}
                                key={i}
                                css={css`
                                    color: ${colors.whisperLight};
                                    margin: 0 0 32px;
                                    &:hover {
                                        color: ${colors.brandBlueLight};
                                    }
                                `}
                            >
                                {text}
                            </a>
                        )
                    })}
                </div>
            </div>
        </footer>
    )
}

export default Footer
