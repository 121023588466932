import {css} from '@emotion/react'
import {androidAppStoreLink} from '../../../util/appDownloadLinks'
import {colors} from '../../../constants'
import GooglePlayIcon from '../../../images/app/GooglePlayIcon.svg'
import GooglePlayText from '../../../images/app/GooglePlayText.svg'
import DownSwoopArrow from '../../../images/app/DownSwoopArrow.svg'
import {EVENTS, logDplWithAppParams} from '../../../util/dpl'
import {getCountry} from '../../../util/getCountry'

const translations = {
    en: {
        getItOn: 'GET IT ON',
    },
    de: {
        getItOn: 'JETZT BEI',
    },
    nl: {
        getItOn: 'ONTDEK HET OP',
    },
}

const GooglePlayCta = ({languageCode, os, experiment, ctaType, showArrow, overrideStyles}) => {
    const text = translations[languageCode]
    return (
        <a
            href={androidAppStoreLink}
            className="google-play"
            target="__blank"
            rel="noopener nofollow noreferrer"
            css={css`
                background: ${colors.black1New};
                border: 1.5px solid #a6a6a6;
                border-radius: 12px;
                display: inline-flex;
                padding: 6px 12px;
                align-items: center;
                position: relative;
                color: ${colors.whisperLight};
                text-align: left;
                @media (max-width: 900px) {
                    width: 200px;
                    .google-play-icon {
                        transform: scale(1.3);
                        margin-left: 4px;
                    }
                    .google-play-text-svg {
                        transform: scale(1.2);
                        margin-left: 17px;
                    }
                    .get-it-on {
                        transform: scale(0.8);
                        margin-left: -6px;
                    }
                }
                ${overrideStyles};
            `}
            aria-label="Get the Startpage App on Google Play"
            onClick={() => {
                logDplWithAppParams(EVENTS.StartpageAppCtaClick, {
                    os,
                    country: getCountry(),
                    type: ctaType,
                    experiment,
                })
            }}
        >
            <GooglePlayIcon className="google-play-icon" />
            <div
                className="google-play-text"
                css={css`
                    margin-left: 10px;
                    span {
                        display: block;
                        font-size: 12px;
                        margin: 0 0 3px;
                    }
                `}
            >
                <span
                    className="get-it-on"
                    css={css`
                        color: ${colors.whisperLight};
                    `}
                >
                    {text.getItOn}
                </span>
                <GooglePlayText className="google-play-text-svg" />
            </div>
            {showArrow && (
                <DownSwoopArrow
                    className="down-swoop-arrow"
                    css={css`
                        position: absolute;
                        top: 0;
                        right: -50px;
                    `}
                />
            )}
        </a>
    )
}

export default GooglePlayCta
