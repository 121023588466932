import {css} from '@emotion/react'
import Star from '../../../images/app/star.svg'

const translations = {
    en: {
        numReviews: '1500+',
        reviews: '5 star reviews',
        rating: 'rating',
    },
    de: {
        numReviews: 'Über 1500',
        reviews: '5-Sterne Bewertungen',
        rating: 'Bewertung',
    },
    nl: {
        numReviews: '1500+',
        reviews: '5-sterrenrecensies',
        rating: 'beoordeling',
    },
}
const AppStoreRatings = ({languageCode, overrideStyles}) => {
    const text = translations[languageCode]
    return (
        <div
            className="app-store-reviews"
            css={css`
                display: flex;
                margin: auto 0;
                align-items: flex-end;
                justify-content: center;
                color: white;
                span,
                p {
                    color: white;
                    margin: 0;
                    line-height: 1.3;
                }
                @media (max-width: 320px) {
                    flex-direction: column;
                    align-items: center;
                    .reviews {
                        border-right: none;
                        padding-right: 0;
                        margin-right: 0;
                        margin-bottom: 16px;
                    }
                }
                ${overrideStyles};
            `}
        >
            <div
                className="reviews"
                css={css`
                    border-right: 1px solid #717bcd;
                    padding-right: 24px;
                    margin-right: 24px;
                `}
            >
                <p
                    css={css`
                        font-weight: 800;
                        font-size: 24px !important;
                    `}
                >
                    {text.numReviews}
                </p>
                <p
                    css={css`
                        font-size: ${languageCode === 'en' ? '18px' : '15px'} !important;
                    `}
                >
                    {text.reviews}
                </p>
            </div>
            <div className="app-store-ratings">
                <div className="top">
                    <span
                        css={css`
                            font-weight: 800;
                            font-size: 24px !important;
                            margin-right: 5px !important;
                            display: inline-block;
                        `}
                    >
                        4.9
                    </span>
                    <Star />
                </div>
                <p
                    css={css`
                        font-size: ${languageCode === 'en' ? '18px' : '15px'} !important;
                    `}
                >
                    {text.rating}
                </p>
            </div>
        </div>
    )
}

export default AppStoreRatings
