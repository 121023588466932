import {css} from '@emotion/react'
import {useStaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'
import {colors, gethomeLink} from '../../../constants'
import StartpageLogoAppBeta from '../../../images/startpage-logo-app-beta.svg'
import Inter from '../../../fonts/Inter'
import PhoneShadow from '../../../images/app/phones/phone-fullsize-shadow.svg'
import BigAppIcon from '../../../images/app/big-app-icon.svg'
import SlashIcon from '../../../images/app/slash-icon.svg'
import DownArrow from '../../../images/app/DownArrow.svg'
import Swoosh from '../../../images/app/swoosh.svg'
import QrCodeWidget from './QrCodeWidget'
import GooglePlayCta from './GooglePlayCta'
import RibbonWidget from './RibbonWidget'

const translations = {
    en: {
        newTitle: 'Search privately on your phone',
        newDescription: 'Get the Startpage app & prevent:',
        newBullets: [
            'Search tracking',
            'Browser history tracking',
            'Collection of your data to train AI models',
            'Unwanted tabs & browsing data with a Zap ⚡',
        ],
        androidCallout: 'Or, click here to install directly on your Android:',
        ribbonNumber: '200K+',
        ribbonText: 'Downloads',
    },
    de: {
        newTitle: 'Private Suche auf dem mobilen Telefon',
        newDescription: 'Die Startpage-App herunterladen und sicher sein vor:',
        newBullets: [
            'Suchverfolgung',
            'Tracking des Browserverlaufs',
            'dem Sammeln deiner Daten zum Trainieren von AI-Modellen',
            'Unerwünschten Tabs und Browserdaten mit Zap ⚡',
        ],
        androidCallout: 'Oder klicke hier, um die App auf deinem Android-Gerät zu installieren:',
        ribbonNumber: '200K+',
        ribbonText: 'Downloads',
    },
    nl: {
        bullets: ['Zoekopdrachten opslaan', 'Browsegeschiedenis bijhouden', 'Data gebruiken om AI-modellen te trainen'],
        newTitle: 'Zoek privé op je telefoon',
        newDescription: 'Download de Startpage-app en voorkom:',
        newBullets: [
            'Dat je gevolgd kan worden door zoekmachines',
            'Dat je gevolgd kan worden via je browser-geschiedenis',
            'Dat je gegevens verzameld worden om AI-modellen te trainen',
            'Ongewenste tabbladen en browsegegevens met Zap ⚡',
        ],
        androidCallout: 'Of klik hier om direct op je Android te installeren:',
        ribbonNumber: '200K+',
        ribbonText: 'Downloads',
    },
}

const UnSupported = ({os, browser, experimentId, languageCode}) => {
    const text = translations[languageCode]

    const site = useStaticQuery(graphql`
        query UnsupportedAppV1Query {
            androidPhoneFullsize: file(relativePath: {eq: "app/phones/android-fullsize-en.png"}) {
                childImageSharp {
                    fixed(height: 567, width: 275, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `)

    return (
        <div
            className="unsupported"
            css={css`
                ${Inter};
                font-family: Inter, sans-serif;
                background: linear-gradient(180deg, #010102 0%, #6371f9 100%);
                padding: 20px 20px 100px;
                height: 100%;
                position: relative;
            `}
        >
            <div
                className="content"
                css={css`
                    max-width: 1000px;
                    margin: 30px auto;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                `}
            >
                <div
                    className="body"
                    css={css`
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;
                        margin: 30px 0 0;
                    `}
                >
                    <div
                        className="text-section"
                        css={css`
                            max-width: 700px;
                            margin-right: 54px;
                            h1,
                            p,
                            span {
                                color: ${colors.whisperLight};
                            }
                            h1 {
                                line-height: ${languageCode === 'en' ? '58px' : '55px'};
                                font-size: ${languageCode === 'en' ? '50px' : '42px'};
                                font-weight: 800;
                                max-width: ${languageCode === 'en' ? '500px' : '600px'};
                            }
                            .description {
                                margin: 40px 0 15px;
                                font-weight: 700;
                                font-size: 18px;
                            }
                        `}
                    >
                        <a
                            href={gethomeLink()}
                            css={css`
                                display: block;
                                margin: 0 0 54px;
                            `}
                            aria-label="Startpage Beta"
                        >
                            <StartpageLogoAppBeta />
                        </a>
                        <h1>{text.newTitle}</h1>
                        <div
                            className="description-container"
                            css={css`
                                position: relative;
                                display: inline-block;
                                svg {
                                    position: absolute;
                                    bottom: 11px;
                                    right: 3px;
                                }
                            `}
                        >
                            <p className="description">{text.newDescription}</p>
                            {languageCode === 'en' && <Swoosh />}
                        </div>
                        <div
                            className="bullets"
                            css={css`
                                margin: 0 0 50px;
                            `}
                        >
                            {text.newBullets.map((bullet) => {
                                return (
                                    <div
                                        className="bullet"
                                        key={bullet}
                                        css={css`
                                            display: flex;
                                            align-items: center;
                                            margin: 0 0 10px;
                                            font-size: 18px;
                                            svg {
                                                margin-right: 10px;
                                            }
                                        `}
                                    >
                                        <SlashIcon alt="" />
                                        <span>{bullet}</span>
                                    </div>
                                )
                            })}
                        </div>

                        <QrCodeWidget
                            languageCode={languageCode}
                            experimentId={experimentId}
                            overrideStyles={css`
                                margin: 0 0 45px;
                            `}
                        />

                        <div className="google-play-section">
                            <p
                                className="android-callout"
                                css={css`
                                    margin: 0 0 24px;
                                    font-size: 18px;
                                    font-weight: 700;
                                `}
                            >
                                {text.androidCallout}
                            </p>
                            <GooglePlayCta
                                languageCode={languageCode}
                                showArrow
                                os={os}
                                experiment={experimentId}
                                ctaType="unsupported-android-cta-1"
                                overrideStyles={css`
                                    border: none;
                                `}
                            />
                        </div>
                    </div>
                    <div
                        className="image"
                        css={css`
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            position: relative;
                            @media (max-width: 900px) {
                                display: none;
                            }
                        `}
                    >
                        <Img alt="" fixed={site.androidPhoneFullsize.childImageSharp.fixed} />
                        <RibbonWidget
                            languageCode={languageCode}
                            overrideStyles={css`
                                position: absolute;
                                top: -58px;
                                right: -58px;
                            `}
                        />
                        <BigAppIcon
                            css={css`
                                position: absolute;
                                top: 360px;
                                left: -60px;
                            `}
                        />
                        <PhoneShadow />
                    </div>
                </div>
            </div>
            <DownArrow
                css={css`
                    position: absolute;
                    bottom: 24px;
                    left: 50%;
                    margin-left: -42px;
                    cursor: pointer;
                    @media (max-width: 900px) {
                        display: none;
                    }
                `}
                onClick={() => {
                    window.scrollTo({top: window.innerHeight, behavior: 'smooth'})
                }}
            />
        </div>
    )
}

export default UnSupported
