import React, {useEffect, useState} from 'react'
import {css} from '@emotion/react'
import Hero from './Hero'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Footer from './Footer'
import {EVENTS, logDplWithAppParams} from '../../../util/dpl'
import {getCountry} from '../../../util/getCountry'
import {logSgc} from '../../../util/extensionMetadata'
import UnSupported from './UnSupported'
import {colors} from '../../../constants'
import {logHoudini} from '../../../util/houdini'
import AppFavicons from './AppFavicons'
import {getUrlParameterByName} from '../../../util/url'
import {androidAppStoreLink, iOSAppStoreLink} from '../../../util/appDownloadLinks'
import IOSButton from './IOSButton'
import GooglePlayCta from './GooglePlayCta'
import ZapPromoSectionDesktop from './ZapPromoSectionDesktop'
import ZapPromoSectionMobile from './ZapPromoSectionMobile'

const AppV1 = ({os, browser, experimentId, languageCode = `en`}) => {
    const isMobile = new Set(['ios', 'android']).has(os)

    const [showPage, setShowPage] = useState(false)
    const [showIOSBanner, setShowIOSBanner] = useState(false)

    useEffect(() => {
        if (!experimentId) return
        logHoudini(experimentId)
        logSgc()
        const shouldRedirect = Boolean(getUrlParameterByName('redirect')) && isMobile
        if (shouldRedirect) {
            logDplWithAppParams(EVENTS.StartpageAppRedirect, {
                os,
                country: getCountry(),
                experiment: experimentId,
            }).then(() => {
                if (os === 'ios') {
                    window.location.href = iOSAppStoreLink
                }
                if (os === 'android') {
                    window.location.href = androidAppStoreLink
                }
                // setTimeout delays showing the page content so user just sees white screen before being
                // redirected to the app (in an attempt to make the transition smoother)
                // still, we wanna show the page eventually if the redirect doesnt happen, so this here is the fallback
                setTimeout(() => {
                    setShowPage(true)
                }, 2000)
            })
        } else {
            setShowPage(true)
            logDplWithAppParams(EVENTS.StartpageAppPageLoad, {
                os,
                country: getCountry(),
                experiment: experimentId,
            })
        }
    }, [experimentId, isMobile, os])

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 100) {
                setShowIOSBanner(true)
            } else {
                setShowIOSBanner(false)
            }
        }
        window.addEventListener('scroll', onScroll)
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    })

    const getHero = () => {
        return (
            <div
                className="hero-wrapper"
                css={css`
                    .mobile {
                        display: none;
                    }
                    @media (max-width: 900px) {
                        .desktop {
                            display: none;
                        }
                        .mobile {
                            display: block;
                        }
                    }
                `}
            >
                <div className="desktop">
                    <UnSupported os={os} browser={browser} experimentId={experimentId} languageCode={languageCode} />
                </div>
                <div className="mobile">
                    <Hero experimentId={experimentId} os={os} browser={browser} languageCode={languageCode} />
                </div>
            </div>
        )
    }

    return (
        <>
            <AppFavicons />
            <div
                className="app-v1"
                css={css`
                    width: 100%;
                    overflow: hidden;
                    visibility: ${showPage ? 'visible' : 'hidden'};
                    p, span {
                        color ${colors.black} !important;
                    }
                `}
            >
                {getHero()}
                <Section1 experimentId={experimentId} os={os} browser={browser} languageCode={languageCode} />
                <div
                    className="section-2-mobile"
                    css={css`
                        display: none;
                        @media (max-width: 900px) {
                            display: block;
                        }
                    `}
                >
                    <Section2 experimentId={experimentId} os={os} browser={browser} languageCode={languageCode} />
                </div>
                <div
                    className="zap-promo-desktop"
                    css={css`
                        @media (max-width: 900px) {
                            display: none;
                        }
                    `}
                >
                    <ZapPromoSectionDesktop languageCode={languageCode} />
                </div>
                <div
                    className="zap-promo-mobile"
                    css={css`
                        display: none;
                        @media (max-width: 900px) {
                            display: block;
                        }
                    `}
                >
                    <ZapPromoSectionMobile languageCode={languageCode} />
                </div>
                <Section3 experimentId={experimentId} os={os} browser={browser} languageCode={languageCode} />
                <Section4 experimentId={experimentId} os={os} browser={browser} languageCode={languageCode} />
                <Footer experimentId={experimentId} os={os} browser={browser} languageCode={languageCode} />
            </div>
            {isMobile && (
                <div
                    className="sticky-button"
                    css={css`
                        position: fixed;
                        bottom: 0;
                        background: linear-gradient(180deg, #0c0d0f 0%, #202c46 100%);
                        padding: 16px 16px 29px;
                        width: 100%;
                        display: ${showIOSBanner ? 'flex' : 'none'};
                        justify-content: center;
                        z-index: 999;
                    `}
                >
                    {os === 'ios' && (
                        <IOSButton
                            ctaType="cta-sticky"
                            experiment={experimentId}
                            os={os}
                            browser={browser}
                            languageCode={languageCode}
                        />
                    )}
                    {os === 'android' && (
                        <GooglePlayCta
                            ctaType="cta-sticky"
                            experiment={experimentId}
                            os={os}
                            browser={browser}
                            languageCode={languageCode}
                        />
                    )}
                </div>
            )}
        </>
    )
}

export default AppV1
