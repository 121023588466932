/* eslint-disable no-param-reassign */
import {css} from '@emotion/react'
import {colors, gethomeLink} from '../../../constants'
import StartpageLogoAppBeta from '../../../images/startpage-logo-app-beta.svg'
import IphoneImageEn from '../../../images/app/phones/iphone-en.svg'
import IphoneImageDe from '../../../images/app/phones/iphone-de.svg'
import IphoneImageNl from '../../../images/app/phones/iphone-nl.svg'
import AndroidImageEn from '../../../images/app/phones/android-en.svg'
import AndroidImageDe from '../../../images/app/phones/android-de.svg'
import AndroidImageNl from '../../../images/app/phones/android-nl.svg'

import BigAppIcon from '../../../images/app/big-app-icon-mobile.svg'
import SlashIcon from '../../../images/app/slash-icon.svg'
import Swoosh from '../../../images/app/swoosh.svg'
import IOSButton from './IOSButton'
import AppStoreRatings from './AppStoreRatings'
import GooglePlayCta from './GooglePlayCta'
import RibbonWidget from './RibbonWidget'

const CONTAINER_MAX_WIDTH = 966

const translations = {
    en: {
        title: `Private search and beyond`,
        title2: `Private search meets private browsing.`,
        description: `Startpage does NOT:`,
        bullets: ['Store searches', 'Track browsing history', 'Use data to train AI models'],
        bodyText: `Search without tracking or profiling on Startpage.`,
        newTitle: 'Search privately on your {}',
        newDescription: 'Get the Startpage app & prevent:',
        newBullets: [
            'Search tracking',
            'Browser history tracking',
            'Collection of your data to train AI models',
            'Unwanted tabs & browsing data with a Zap ⚡',
        ],
    },
    de: {
        title: `Private Suche und mehr`,
        title2: `Private Suche und privates Surfen kombiniert.`,
        description: `Was Startpage NICHT tut:`,
        bullets: [
            'deine Suchergebnisse speichern',
            'deinen Suchverlauf speichern',
            'Daten nutzen, um KI-Modelle zu trainieren.',
        ],
        bodyText: `Suche ohne Tracking oder Profilerstellung mit Startpage.`,
        newTitle: 'Private Suche auf {}',
        newDescription: 'Die Startpage-App herunterladen und sicher sein vor:',
        newBullets: [
            'Suchverfolgung',
            'Tracking des Browserverlaufs',
            'dem Sammeln deiner Daten zum Trainieren von AI-Modellen',
            'Unerwünschten Tabs und Browserdaten mit Zap ⚡',
        ],
    },
    nl: {
        title: `Privé zoeken en meer`,
        title2: `Privé zoeken ontmoet privé browsen.`,
        description: `Wat Startpage NIET doet:`,
        bullets: ['Zoekopdrachten opslaan', 'Browsegeschiedenis bijhouden', 'Data gebruiken om AI-modellen te trainen'],
        bodyText: `Zoek zonder tracking of profilering op Startpage.`,
        newTitle: 'Zoek privé op je {}',
        newDescription: 'Download de Startpage-app en voorkom:',
        newBullets: [
            'Dat je gevolgd kan worden door zoekmachines',
            'Dat je gevolgd kan worden via je browser-geschiedenis',
            'Dat je gegevens verzameld worden om AI-modellen te trainen',
            'Ongewenste tabbladen en browsegegevens met Zap ⚡',
        ],
    },
}

const Hero = ({experimentId, os, browser, languageCode}) => {
    const text = translations[languageCode]

    const getLocalizedImage = () => {
        if (os === 'android') {
            switch (languageCode) {
                case 'de':
                    return <AndroidImageDe alt="" />
                case 'nl':
                    return <AndroidImageNl alt="" />
                default:
                    return (
                        <AndroidImageEn
                            css={css`
                                /* NOTE: the asset in figma didnt match the dimensions of the other languages, so I alter it here */
                                transform: scale(1.2) translate(0, -16px);
                                margin-top: 2rem;
                            `}
                            alt=""
                        />
                    )
            }
        }
        switch (languageCode) {
            case 'de':
                return <IphoneImageDe alt="" />
            case 'nl':
                return <IphoneImageNl alt="" />
            default:
                return <IphoneImageEn alt="" />
        }
    }

    return (
        <section
            className="hero"
            css={css`
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, #000 0.45%, #17203b 29.1%, #6573ff 101.25%);
            `}
        >
            <div
                className="header"
                css={css`
                    width: 100%;
                    padding: 24px 24px 10px;
                `}
            >
                <div
                    className="header-inner"
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        max-width: ${CONTAINER_MAX_WIDTH}px;
                        margin: 0 auto;
                    `}
                >
                    <a
                        href={gethomeLink(languageCode)}
                        target="__blank"
                        rel="noopener noreferrer nofollow"
                        className="logo"
                        css={css`
                            display: block;
                            /* TODO: remove the styles below if/when you switch back to StartpageLogoRainbow */
                            transform: scale(0.75);
                            margin-left: -21px;
                            margin-top: -5px;
                            /* end */
                        `}
                    >
                        <StartpageLogoAppBeta />
                    </a>
                </div>
            </div>

            <div
                className="hero-body"
                css={css`
                    max-width: ${CONTAINER_MAX_WIDTH}px;
                    margin: 0 auto;
                `}
            >
                <div
                    className="billboard"
                    css={css`
                        text-align: center;
                        padding: 0 1rem;
                        h1,
                        p {
                            color: ${colors.whisperLight};
                        }
                        h1 {
                            font-size: 32px;
                            margin: 0 auto 20px;
                            max-width: 325px;
                        }
                        p,
                        span {
                            font-size: 16px;
                        }
                        .intro,
                        .title {
                            font-weight: 800;
                        }
                    `}
                >
                    <BigAppIcon
                        css={css`
                            transform: scale(0.7);
                            margin-top: -10px;
                        `}
                    />
                    <h1 className="title">{text.newTitle.replace('{}', os === 'android' ? 'Android' : 'iPhone')}</h1>
                    <div
                        className="bullet-section"
                        css={css`
                            max-width: 286px;
                            text-align: left;
                            margin: 0 auto 18px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                        `}
                    >
                        <div
                            className="subtitle-section"
                            css={css`
                                position: relative;
                                svg {
                                    position: absolute;
                                    right: 0px;
                                    top: 24px;
                                    @media (max-width: 350px) {
                                        display: none;
                                    }
                                }
                            `}
                        >
                            <p
                                className="subtitle"
                                css={css`
                                    font-weight: 700;
                                    margin: 0 0 20px;
                                `}
                            >
                                {text.newDescription}
                            </p>
                            {languageCode === 'en' && <Swoosh />}
                        </div>
                        <div className="bullets" css={css``}>
                            {text.newBullets.map((bullet) => {
                                return (
                                    <div
                                        className="bullet"
                                        key={bullet}
                                        css={css`
                                            display: flex;
                                            align-items: center;
                                            margin: 0 0 15px;
                                            span {
                                                color: ${colors.whisperLight};
                                            }
                                            svg {
                                                margin-right: 10px;
                                            }
                                        `}
                                    >
                                        <SlashIcon
                                            css={css`
                                                min-width: 20px;
                                            `}
                                        />
                                        <span>{bullet}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div
                        className="cta-container"
                        css={css`
                            margin: 0 0 30px;
                        `}
                    >
                        {os === 'android' ? (
                            <GooglePlayCta
                                ctaType="cta-1"
                                experiment={experimentId}
                                os={os}
                                browser={browser}
                                languageCode={languageCode}
                            />
                        ) : (
                            <IOSButton
                                ctaType="cta-1"
                                experiment={experimentId}
                                os={os}
                                browser={browser}
                                languageCode={languageCode}
                            />
                        )}
                        <AppStoreRatings
                            languageCode={languageCode}
                            overrideStyles={css`
                                margin: 0 0 70px;
                                @media (max-width: 900px) {
                                    margin: 30px 0 80px;
                                }
                            `}
                        />
                    </div>
                </div>
                <div
                    className="phone-image"
                    css={css`
                        max-width: 280px;
                        margin: 0 auto;
                        position: relative;
                        svg {
                            margin-bottom: -4px;
                        }
                    `}
                >
                    {getLocalizedImage()}
                    <RibbonWidget
                        id="ribbon-widget-2"
                        languageCode={languageCode}
                        overrideStyles={css`
                            position: absolute;
                            top: -54px;
                            right: -58px;
                        `}
                    />
                </div>
            </div>
        </section>
    )
}

export default Hero
