import {css} from '@emotion/react'
import {colors} from '../../../constants'
import GooglePlayCta from './GooglePlayCta'

const translations = {
    en: {
        androidCallout: 'Or, click here to install directly on your Android:',
    },
    de: {
        androidCallout: 'Oder klicke hier, um die App auf deinem Android-Gerät zu installieren:',
    },
    nl: {
        androidCallout: 'Of klik hier om direct op je Android te installeren:',
    },
}

const GooglePlayWidget = ({languageCode, os, experiment, ctaType}) => {
    const text = translations[languageCode]
    return (
        <div
            className="google-play-widget"
            css={css`
                background: ${colors.whisperLight};
                border-radius: 24px;
                padding: 20px 24px 8px;
                align-items: center;
                max-width: ${languageCode === 'en' ? '370px' : '420px'};
                /* margin: 0 0 45px; */
            `}
        >
            <h2
                className="google-play-widget-title"
                css={css`
                    font-size: 18px;
                    color: ${colors.black};
                    margin-bottom: 26px;
                `}
            >
                {text.androidCallout}
            </h2>
            <GooglePlayCta
                languageCode={languageCode}
                showArrow
                os={os}
                experiment={experiment}
                ctaType={ctaType}
                overrideStyles={css`
                    border: none;
                    .down-swoop-arrow {
                        right: -43px;
                        path {
                            fill: ${colors.purple};
                        }
                    }
                `}
            />
        </div>
    )
}

export default GooglePlayWidget
