import {css} from '@emotion/react'
import {useStaticQuery, graphql} from 'gatsby'
import {useState, useEffect} from 'react'
import Img from 'gatsby-image'
import {colors} from '../../../constants'
import {getAllAppParams} from '../../../util/appMetadata'

const translations = {
    en: {
        newQrCodeTitle: 'Download for iPhone or Android:',
        newQrCodeDescription: 'Scan the QR code with your phone to get the app.',
        qrCodeAlt: `Startpage mobile app QR code`,
    },
    de: {
        qrCodeAlt: `Startpage mobile app QR code`,
        newQrCodeTitle: 'Download für iPhone oder Android:',
        newQrCodeDescription: 'Scanne zum Herunterladen der App den QR-Code.',
    },
    nl: {
        qrCodeAlt: `Startpage mobile app QR code`,
        newQrCodeTitle: 'Download voor iPhone of Android:',
        newQrCodeDescription: 'Scan de QR-code met je telefoon om de app te downloaden.',
    },
}

const QrCodeWidget = ({languageCode, experimentId, overrideStyles}) => {
    const text = translations[languageCode]
    const site = useStaticQuery(graphql`
        query QrCodeWidgetQuery {
            blogQrCode: file(relativePath: {eq: "app/qr-codes/en/blog.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            discordQrCode: file(relativePath: {eq: "app/qr-codes/en/discord.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            facebookQrCode: file(relativePath: {eq: "app/qr-codes/en/facebook.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            instagramQrCode: file(relativePath: {eq: "app/qr-codes/en/instagram.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            linkedinQrCode: file(relativePath: {eq: "app/qr-codes/en/linkedin.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            mastodonQrCode: file(relativePath: {eq: "app/qr-codes/en/mastodon.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            newsletterQrCode: file(relativePath: {eq: "app/qr-codes/en/newsletter.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            redditQrCode: file(relativePath: {eq: "app/qr-codes/en/reddit.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            threadsQrCode: file(relativePath: {eq: "app/qr-codes/en/threads.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            tiktokQrCode: file(relativePath: {eq: "app/qr-codes/en/tiktok.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            twitterQrCode: file(relativePath: {eq: "app/qr-codes/en/twitter.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            youtubeQrCode: file(relativePath: {eq: "app/qr-codes/en/youtube.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            organicQrCode: file(relativePath: {eq: "app/qr-codes/en/organic.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            blogQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/blog.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            discordQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/discord.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            facebookQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/facebook.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            instagramQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/instagram.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            linkedinQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/linkedin.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            mastodonQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/mastodon.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            newsletterQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/newsletter.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            redditQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/reddit.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            threadsQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/threads.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            tiktokQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/tiktok.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            twitterQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/twitter.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            youtubeQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/youtube.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            organicQrCodeDe: file(relativePath: {eq: "app/qr-codes/de/organic.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            blogQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/blog.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            discordQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/discord.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            facebookQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/facebook.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            instagramQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/instagram.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            linkedinQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/linkedin.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            mastodonQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/mastodon.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            newsletterQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/newsletter.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            redditQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/reddit.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            threadsQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/threads.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            tiktokQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/tiktok.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            twitterQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/twitter.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            youtubeQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/youtube.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            organicQrCodeNl: file(relativePath: {eq: "app/qr-codes/nl/organic.png"}) {
                childImageSharp {
                    fixed(height: 114, width: 114, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `)

    const qrCodeMap = {
        en: {
            blog: site.blogQrCode.childImageSharp.fixed,
            discord: site.discordQrCode.childImageSharp.fixed,
            facebook: site.facebookQrCode.childImageSharp.fixed,
            instagram: site.instagramQrCode.childImageSharp.fixed,
            linkedin: site.linkedinQrCode.childImageSharp.fixed,
            mastodon: site.mastodonQrCode.childImageSharp.fixed,
            newsletter: site.newsletterQrCode.childImageSharp.fixed,
            organic: site.organicQrCode.childImageSharp.fixed,
            reddit: site.redditQrCode.childImageSharp.fixed,
            threads: site.threadsQrCode.childImageSharp.fixed,
            tiktok: site.tiktokQrCode.childImageSharp.fixed,
            twitter: site.twitterQrCode.childImageSharp.fixed,
            youtube: site.youtubeQrCode.childImageSharp.fixed,
        },
        nl: {
            blog: site.blogQrCodeNl.childImageSharp.fixed,
            discord: site.discordQrCodeNl.childImageSharp.fixed,
            facebook: site.facebookQrCodeNl.childImageSharp.fixed,
            instagram: site.instagramQrCodeNl.childImageSharp.fixed,
            linkedin: site.linkedinQrCodeNl.childImageSharp.fixed,
            mastodon: site.mastodonQrCodeNl.childImageSharp.fixed,
            newsletter: site.newsletterQrCodeNl.childImageSharp.fixed,
            organic: site.organicQrCodeNl.childImageSharp.fixed,
            reddit: site.redditQrCodeNl.childImageSharp.fixed,
            threads: site.threadsQrCodeNl.childImageSharp.fixed,
            tiktok: site.tiktokQrCodeNl.childImageSharp.fixed,
            twitter: site.twitterQrCodeNl.childImageSharp.fixed,
            youtube: site.youtubeQrCodeNl.childImageSharp.fixed,
        },
        de: {
            blog: site.blogQrCodeDe.childImageSharp.fixed,
            discord: site.discordQrCodeDe.childImageSharp.fixed,
            facebook: site.facebookQrCodeDe.childImageSharp.fixed,
            instagram: site.instagramQrCodeDe.childImageSharp.fixed,
            linkedin: site.linkedinQrCodeDe.childImageSharp.fixed,
            mastodon: site.mastodonQrCodeDe.childImageSharp.fixed,
            newsletter: site.newsletterQrCodeDe.childImageSharp.fixed,
            organic: site.organicQrCodeDe.childImageSharp.fixed,
            reddit: site.redditQrCodeDe.childImageSharp.fixed,
            threads: site.threadsQrCodeDe.childImageSharp.fixed,
            tiktok: site.tiktokQrCodeDe.childImageSharp.fixed,
            twitter: site.twitterQrCodeDe.childImageSharp.fixed,
            youtube: site.youtubeQrCodeDe.childImageSharp.fixed,
        },
    }

    const [qrCodeImage, setQrCodeImage] = useState(qrCodeMap[languageCode].organic)

    useEffect(() => {
        const appParams = getAllAppParams(experimentId)
        const {source} = appParams
        const image = qrCodeMap[languageCode][source]
        if (image) {
            setQrCodeImage(image)
        }
    }, [experimentId, languageCode, qrCodeMap])

    return (
        <div
            className="qr-code-section"
            css={css`
                background: ${colors.whisperLight};
                border-radius: 24px;
                padding: 20px 24px 8px;
                align-items: center;
                text-align: left;
                max-width: ${languageCode === 'en' ? '370px' : '420px'};
                a,
                span,
                p {
                    color: ${colors.black};
                }
                .gatsby-image-wrapper,
                img {
                    border-radius: 12px;
                    overflow: hidden;
                }
                ${overrideStyles};
            `}
        >
            <h2
                className="qr-code-section-title"
                css={css`
                    font-size: 18px;
                    font-weight: 700;
                    color: ${colors.black};
                `}
            >
                {text.newQrCodeTitle}
            </h2>
            <div
                className="details"
                css={css`
                    display: flex;
                    align-items: center;
                    margin-top: -4px;
                `}
            >
                <div
                    className="qr-code"
                    css={css`
                        border: 4px solid ${colors.purple};
                        border-radius: 12px;
                        transform: scale(0.7);
                        margin-left: -20px;
                    `}
                >
                    <Img alt={text.qrCodeAlt} fixed={qrCodeImage} />
                </div>
                <p
                    css={css`
                        font-size: 16px;
                        margin: 0 0 12px;
                        line-height: 22px;
                        max-width: 255px;
                    `}
                >
                    {text.newQrCodeDescription}
                </p>
            </div>
        </div>
    )
}

export default QrCodeWidget
